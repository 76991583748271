<template>
  <div class="container">
    <div v-if="!email">
      <Alert type="error">
        An error prompt
        <span slot="desc">
          This looks like a wrong link, please check if the link is correct.
        </span>
      </Alert>
    </div>
    <div class="box" v-else>
      <Icon class="icon success mb-20" type="md-checkmark-circle" />
      <h1 class="mb-10">Account registration succeeded</h1>
      <h3 class="mb-20">Please check email and click the confirm link</h3>

      <p class="mb-20">
        No email received?
        <Button @click="sendVerifyEmail" :loading="sendVerifyEmailLoading"
          >Resend email</Button
        >
      </p>
      <router-link to="/user-action/login">
        <Button type="primary">Back To Login</Button>
      </router-link>
    </div>
  </div>
</template>

<script>
import api from "@/api";
const { _sendVerifyEmail } = api;

export default {
  created() {
    const email = this.$route.query.email;
    this.email = email;
  },
  data() {
    return {
      email: "",
      sendVerifyEmailLoading: false
    };
  },
  methods: {
    sendVerifyEmail() {
      this.sendVerifyEmailLoading = true;
      _sendVerifyEmail({
        email: this.email
      })
        .then(() => {
          this.$Message.success("Resend email successed");
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.sendVerifyEmailLoading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  .icon {
    font-size: 100px;
    &.success {
      color: #19be6b;
    }
    &.fail {
      color: #ed4014;
    }
  }
}
</style>
